import { useMemo, useState, useCallback } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import cx from 'classnames'

import { Container, BlockWrapper } from 'common/widgets'
import { ImageType } from '../types'
import ImageItem from './ImageItem'
import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


ImageGridWithFilter.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      tag: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      cta_link: PropTypes.string,
    })),
    cta_link_caption: PropTypes.string,
    cta_link_type: PropTypes.string,
  }),
}

ImageGridWithFilter.defaultProps = {
  data: {
    columns: [],
  },
}

function TransitionRow(props) {
  return <Row {...props} gutter={[30, 30]} />
}

export default function ImageGridWithFilter({ data }) {
  const { gettext } = useTranslations()
  const [selectedTag, setTag] = useState(gettext('All'))
  const setTagHandler = useCallback(tag => {
    setTag('')
    setTimeout(() => setTag(tag), 300)
  })
  const tags = useMemo(() => {
    const tags = [gettext('All'), ...new Set(data.columns.map(({ tag }) => tag))]

    return tags.map(tag => (
      <li key={tag} className={classNames.tag}>
        <button
          className={cx(classNames.tagBtn, tag === selectedTag && classNames.selected)}
          onClick={setTagHandler.bind(null, tag)}
        >
          {tag}
        </button>
      </li>
    ))
  }, [data.columns, selectedTag])

  const items = useMemo(() => data.columns.map(({ tag, ...option }) => {
    return [gettext('All'), tag].includes(selectedTag) && (
      <CSSTransition
        key={option.name}
        timeout={300}
        classNames={{
          enter: classNames.enter,
          enterActive: classNames.enterActive,
          exit: classNames.exit,
          exitActive: classNames.exitActive,
        }}
      >
        <ImageItem {...option} caption={data.cta_link_caption} linkType={data.cta_link_type} tag={tag} />
      </CSSTransition>
    )
  }), [data.columns, selectedTag])

  return (
    <BlockWrapper>
      <Container size="xl">
        <ul className={classNames.tags}>
          {tags}
        </ul>
        <TransitionGroup component={TransitionRow}>
          {items}
        </TransitionGroup>
      </Container>
    </BlockWrapper>
  )
}
