import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import get from 'lodash/get'
import Image from 'next/image'

import { CTALink, SquareBlock, Typography } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import classNames from './styles.module.scss'


ImageItem.propTypes = {
  image: PropTypes.string,
  subheading: PropTypes.string,
  heading: PropTypes.string,
  caption: PropTypes.string,
  tag: PropTypes.string,
  cta_link: PropTypes.string,
  linkType: PropTypes.string,
}

ImageItem.defaultProps = {
  image: undefined,
  subheading: undefined,
  heading: undefined,
  caption: undefined,
  tag: undefined,
  cta_link: undefined,
  linkType: undefined,
}

export default function ImageItem({ image, heading, subheading, caption, tag, cta_link: url, linkType }) {
  const src = useMemo(() => getImgFromBynder(get(image, 'bynder'), ['webbig', 'webImage']), [image])
  const alt = useMemo(() => get(image, 'data.alttext'), [image])
  const title = useMemo(() => get(image, 'data.name'), [image])

  return (
    <Col span={24} md={12} lg={8}>
      <SquareBlock
        containerClassName={classNames.container}
        innerClassName={classNames.inner}
        image={<Image src={src} alt={alt} title={title} layout="fill" objectFit="cover" priority />}
      >
        <Typography variant="content" color="white" className={classNames.name}><b>{tag}</b></Typography>
        <Typography variant="h3" color="white" className={classNames.name}>{heading}</Typography>
        <Typography color="white">{subheading}</Typography>
        <CTALink
          cta={caption}
          ctaURL={url}
          isUnderline
          type={linkType}
        />
      </SquareBlock>
    </Col>
  )
}
