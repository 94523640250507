import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { Container, BlockWrapper } from 'common/widgets'
import Person from './Person'
import DetailModal from './widgets/DetailModal'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


TestimonialShowcase.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      name: PropTypes.string,
      title: PropTypes.string,
      quote: PropTypes.string,
    })),
  }),
}

TestimonialShowcase.defaultProps = {
  data: {
    items: [],
  },
}

export default function TestimonialShowcase({ data }) {
  const [selectedPerson, setSelectedPerson] = useState()

  const handleClick = useCallback(index => {
    setSelectedPerson(selectedPerson => !isEmpty(selectedPerson) ? {} : data.items[index])
  }, [data.items, setSelectedPerson])

  const options = useMemo(() => data.items.map((option, index) => (
    <Person key={option.name} {...option} onClick={handleClick.bind(null, index)} />
  )), [data.items])

  return (
    <BlockWrapper>
      <Container
        size="xl"
        className={cx(classNames.container, !isEmpty(selectedPerson) && classNames.ready)}
      >
        <Row gutter={[32, 32]} className={classNames.row}>
          {options}
        </Row>
        <DetailModal person={selectedPerson} />
      </Container>
    </BlockWrapper>
  )
}
